<template>
  <el-form class="column3" label-width="7em" :disabled="true">
    <el-form-item label="项目名称" prop="businessName" ref="businessName">
      <el-input disabled v-model="data.businessName" placeholder="请输入"></el-input>
    </el-form-item>
    <!-- <el-form-item label="商务编号" prop="businessNumber" ref="businessNumber">
            <el-input disabled v-model="data.businessNumber" placeholder="请输入"></el-input>
        </el-form-item> -->
    <el-form-item label="信息提供人" prop="initiatorName" ref="initiatorName">
      <el-input disabled v-model="data.initiatorName" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="业主单位" prop="ownerUnit" ref="ownerUnit">
      <el-input disabled v-model="data.ownerUnit" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="业主联系人" prop="contacts" ref="contacts">
      <el-input disabled v-model="data.contacts" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="联系电话" prop="contactsPhone" ref="contactsPhone">
      <el-input disabled v-model="data.contactsPhone" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="我方合同金额" prop="ourContractAmount" ref="ourContractAmount">
      <el-input disabled v-model="data.ourContractAmount" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="业务类型" prop="businessType" ref="businessType">
      <Dictionary
        disabled
        v-model="data.businessType"
        code="BUSINESS_TYPE"
        placeholder="请选择业务类型"
      />
    </el-form-item>

    <el-form-item label="项目类型" prop="projectType" ref="projectType">
      <Dictionary
        disabled
        v-model="data.projectType"
        code="PROJECT_TYPE"
        placeholder="请选择项目类型"
      />
    </el-form-item>

    <el-form-item label="项目来源" prop="projectSource" ref="projectSource">
      <Dictionary
        disabled
        v-model="data.projectSource"
        code="PROJECT_SOURCE"
        placeholder="请选择项目来源"
      />
    </el-form-item>

    <!-- <el-form-item
      label="项目来源详细"
      prop="projectSourceDetailed"
      ref="projectSourceDetailed"
    >
      <Dictionary
        disabled
        v-model="data.projectSourceDetailed"
        :code="data.projectSource"
        placeholder="请选择项目详情"
      />
    </el-form-item> -->

    <!-- <el-form-item
      label="挂靠合作费用"
      v-if="
        data.projectSource == 'di_san_fang_gong_si_gua_kao_hui_tu_de_xiang_mu'
      "
      prop="collaborationCosts"
      ref="collaborationCosts"
    >
      <el-input
        disabled
        v-model="data.collaborationCosts"
        onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
        placeholder="请输入"
      ></el-input>
      <span class="danwei">（元）</span>
    </el-form-item> -->
    <el-form-item label="外部协助费用" prop="contentCooperationMoney" ref="contentCooperationMoney">
      <el-input disabled v-model="data.contentCooperationMoney"></el-input>
      <span class="danwei">（元）</span>
    </el-form-item>
    <el-form-item label="商务类型" prop="businessType" ref="businessType">
      <Dictionary disabled v-model="data.businessType" code="BUSINESS_TYPE" placeholder="请选择" />
    </el-form-item>
    <el-form-item style="width: 100%" label="商务说明" prop="explain" ref="explain">
      <el-input disabled type="textarea" v-model="data.explain" placeholder="请输入"></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    businessManageId: {},
  },
  data() {
    return {
      data: {},
    }
  },
  inject: ['refresh'],
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
      projectDisposableExpenses: state => state.project.projectDisposableExpenses,
    }),
  },
  watch: {
    businessManageId: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (businessManageId) {
        if (businessManageId) {
          this.$api.businessManage
            .getBusinessManage(businessManageId)
            .then(res => {
              this.data = res.data.businessManage
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
    },
  },
  created() {},
  methods: {},
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
/deep/.el-card__body {
  padding: 32px 20px 10px 20px;
}
.danwei {
  position: absolute;
  right: 2%;
}
</style>
